/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
/*
  Import Tailwind's base styles into the browser.
*/
import "./src/styles/globals.css"
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
		//eslint-disable-next-line
    import('intersection-observer');
  }
}
