// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-distributor-js": () => import("./../../../src/pages/distributor.js" /* webpackChunkName: "component---src-pages-distributor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-project-consultancy-js": () => import("./../../../src/pages/project-consultancy.js" /* webpackChunkName: "component---src-pages-project-consultancy-js" */),
  "component---src-pages-supplier-js": () => import("./../../../src/pages/supplier.js" /* webpackChunkName: "component---src-pages-supplier-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-page-content-js": () => import("./../../../src/templates/page-content.js" /* webpackChunkName: "component---src-templates-page-content-js" */),
  "component---src-templates-product-detail-js": () => import("./../../../src/templates/product-detail.js" /* webpackChunkName: "component---src-templates-product-detail-js" */),
  "component---src-templates-sub-category-js": () => import("./../../../src/templates/sub-category.js" /* webpackChunkName: "component---src-templates-sub-category-js" */)
}

